import { useLocation } from "@reach/router";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image";
import qs from "qs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export const PRODUCT_MAP = {
  AWS_CCP_ALL: {
    code: "AWS_CCP_ALL",
    value: 29.99,
    pathname: "/exams/cloud-practitioner/",
  },
  AWS_SAA_ALL: {
    code: "AWS_SAA_ALL",
    value: 49.99,
    pathname: "/exams/solutions-architect-associate/",
  },
  AWS_DEV_ALL: {
    code: "AWS_DEV_ALL",
    value: 49.99,
    pathname: "/exams/developer-associate/",
  },
};

export const PurchaseCompleteSection: React.FC = () => {
  const [loggedConversion, setLoggedConversion] = useState(false);
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (loggedConversion) {
      return;
    }
    console.log("Logging conversion!");

    typeof window !== "undefined" &&
      (window as any).gtag?.("event", "conversion", {
        send_to: "AW-481086092/YvvLCIig3ewYEIyVs-UB",
        value: queryParams.code
          ? PRODUCT_MAP[queryParams.code]?.value
          : PRODUCT_MAP["AWS_CCP_ALL"].value,
        currency: "USD",
      });

    typeof window !== "undefined" &&
      (window as any).fbq?.("track", "Purchase", {
        value: queryParams.code
          ? PRODUCT_MAP[queryParams.code]?.value
          : PRODUCT_MAP["AWS_CCP_ALL"].value,
        currency: "USD",
      });

    setLoggedConversion(true);
  }, [loggedConversion]);

  return (
    <section className="w-full py-12 bg-gray-50 sm:py-20">
      {/* <Navigation /> */}
      <div>
        {/* <Navigation /> */}
        <div className="flex flex-col items-center justify-center h-screen ">
          <h1 className="text-4xl font-medium text-gray-800">
            Purchase Complete!
          </h1>
          <h2 className="text-2xl font-medium text-gray-800">
            Thank you for your purchase. Unlock your content below
          </h2>

          <div className="my-8">
            <OutboundLink
              href="https://zorbi.app/login?d=AWS_CCP&code=AWS_CCP_ALL"
              className="px-4 py-2 font-medium text-white bg-purple-500 rounded-md hover:bg-purple-600"
            >
              Unlock Content
            </OutboundLink>
          </div>
        </div>
      </div>
    </section>
  );
};
