import React, { useEffect, useState } from "react";
import { Navigation } from "../components/common/navigation/navigation";
import { Footer } from "../components/sections/footer";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import PageLayout from "../components/common/layout/page-layout";

import { PurchaseCompleteSection } from "../components/sections/purchase_complete";

const PurchaseCompletedPage = () => {
  return (
    <Layout>
      <SEO title="Purchase Complete!" />
      <PurchaseCompleteSection />
    </Layout>
  );
};

export default PurchaseCompletedPage;
