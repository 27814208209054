import React from "react";
import SEO from "./seo";
import Layout from "./layout";
import { Footer } from "../../sections/footer";
import { Navigation } from "../navigation/navigation";

const PageLayout = ({ title, children, description, noNavigation = false }) => {
  return (
    <Layout>
      <SEO title={title} description={description ?? ""} />
      {!noNavigation && <Navigation />}
      {children}
      <Footer />
    </Layout>
  );
};

export default PageLayout;
